<template>
  <div class="mx-5">
    <!-- Start: Search area -->
    <div class="d_flex flex-column align-center justify-center">
      <div class="text-h6 font-weight-medium text-center mb-3">
        Nhập serial hoặc số điện thoại khách hàng
      </div>
      <div class="search-box">
        <v-text-field
          v-model="searchKey"
          clearable
          class="text-body-1 rounded-lg"
          prepend-inner-icon="mdi-magnify"
          dense
          flat
          hide-details
          placeholder="Nhập và ấn enter"
          solo
          :disabled="
            guaranteeVoucherStatusRequest.value ===
              'loading-searchGuaranteeInfo'
          "
          @keypress.enter="
            guaranteeVoucherStatusRequest.value ===
            'loading-searchGuaranteeInfo'
              ? null
              : searchGuaranteeInfo()
          "
        ></v-text-field>
      </div>
    </div>
    <!-- End: Search area -->
    <!-- Start: Search results -->
    <template v-if="searchedSerials">
      <!-- Start: Customer info -->
      <v-card class="mt-8" flat v-if="searchedSerials.customer">
        <v-row class="mx-0">
          <v-col class="pa-5">
            <div class="">
              <span class="font-weight-bold">Khách hàng: </span
              >{{ searchedSerials.customer.name }}
            </div>
          </v-col>
          <v-col class="pa-5">
            <div class="text-center">
              <span class="font-weight-bold">SĐT: </span>
              <span v-if="searchedSerials.customer">
                {{ searchedSerials.customer.phone | VMask("### ### ####") }}
              </span>
            </div>
          </v-col>
          <v-col class="pa-5">
            <div class="text-right">
              <span class="font-weight-bold">Đã mua: </span
              >{{ searchedSerials.customer.total_buy }}
            </div>
          </v-col>
        </v-row>
      </v-card>
      <!-- End: Customer info -->
      <!-- Start: Serials List -->
      <div class="d_flex flex-wrap align-center mt-8">
        <div class="font-weight-medium mr-3">Serials:</div>
        <v-chip
          class="serial-item mr-3 my-1"
          :class="{ 'font-weight-medium': activeSerialIndex === index }"
          :color="activeSerialIndex === index ? 'cyan lighten-4' : 'white'"
          v-for="(item, index) in searchedSerials.serials"
          :key="index"
          label
          @click="getSerialInfo(index, item.serial_number)"
        >
          {{ `${item.option_sku} - ${item.serial_number}` }}
        </v-chip>
      </div>
      <!-- End: Serials List -->
      <!-- Start: Serial Info -->
      <v-card class="mt-5" flat v-if="serialInfo">
        <v-card-title class="d-flex align-center justify-space-between px-5">
          <div class="font-weight-bold">
            Số serial: #{{ serialInfo.serial_number }}
          </div>
          <div class="text-body-1">
            <span class="font-weight-bold">Hạn BH: </span>
            <span>{{ serialInfo.guarantee_info.due_time }}</span>
          </div>
          <v-chip
            class="font-weight-medium px-2 ml-2"
            color="orange"
            small
            outlined
          >
            {{ serialInfo.status }}
          </v-chip>
        </v-card-title>
        <!-- Start: Serial info -->
        <serial-info></serial-info>
        <!-- End: Serial info -->
        <!-- Start: Serial history -->
        <serial-history></serial-history>
        <!-- End: Serial history -->
      </v-card>
      <!-- End: Serial info -->
    </template>
    <template v-else>
      <v-card class="amber lighten-5  mt-8" flat>
        <v-card-title
          class="text-h6 font-weight-light grey--text text--darken-2 justify-center text-center"
        >
          Tra cứu thông tin bảo hành của sản phẩm đã bán bằng serial number hoặc
          số điện thoại của khách hàng.
        </v-card-title>
      </v-card>
    </template>
    <!-- End: Search results -->
  </div>
</template>

<script>
import SerialHistory from "./components/SerialHistory";
import SerialInfo from "./components/SerialInfo";

export default {
  components: {
    SerialHistory,
    SerialInfo
  },
  data() {
    return {
      activeSerial: null,
      activeSerialIndex: 0,
      searchKey: null
    };
  },
  computed: {
    guaranteeVoucherStatusRequest() {
      return this.$store.getters["GUARANTEE_VOUCHER/statusRequest"];
    },
    searchedSerials() {
      return this.$store.getters["GUARANTEE_VOUCHER/searchedSerials"];
    },
    serialInfo() {
      return this.$store.getters["GUARANTEE_VOUCHER/serialInfo"];
    }
  },
  async created() {
    const query = this.$route.query;
    if (query && query.key) {
      this.searchKey = query.key;
      // Search guarantee info
      await this.$store.dispatch(
        "GUARANTEE_VOUCHER/searchGuaranteeInfo",
        this.searchKey
      );

      // Check search result
      if (
        this.guaranteeVoucherStatusRequest.value ===
        "success-searchGuaranteeInfo"
      ) {
        await this.$store.dispatch(
          "GUARANTEE_VOUCHER/getSerialInfoBySerialNumber",
          this.searchedSerials.serials[this.activeSerialIndex].serial_number
        );
      } else {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: this.guaranteeVoucherStatusRequest.message,
            message: "Vui lòng thử lại với serial hoặc số điện thoại khác.",
            cancelBtnText: "OK"
          }
        });
      }
    }
  },
  methods: {
    async getSerialInfo(index, serialNumber) {
      if (index !== this.activeSerialIndex) {
        this.activeSerialIndex = index;
        await this.$store.dispatch(
          "GUARANTEE_VOUCHER/getSerialInfoBySerialNumber",
          serialNumber
        );
      }
    },

    async searchGuaranteeInfo() {
      this.activeSerialIndex = 0;
      await this.$store.dispatch(
        "GUARANTEE_VOUCHER/searchGuaranteeInfo",
        this.searchKey
      );

      if (
        this.guaranteeVoucherStatusRequest.value ===
        "success-searchGuaranteeInfo"
      ) {
        await this.$store.dispatch(
          "GUARANTEE_VOUCHER/getSerialInfoBySerialNumber",
          this.searchedSerials.serials[this.activeSerialIndex].serial_number
        );
        await this.$router.push({
          name: "guarantee_search",
          query: {
            key: this.searchKey,
            sn: this.searchedSerials.serials[this.activeSerialIndex]
              .serial_number
          }
        });
      } else {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: this.guaranteeVoucherStatusRequest.message,
            message: "Vui lòng thử lại với serial hoặc số điện thoại khác.",
            cancelBtnText: "OK"
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.serial-item {
  cursor: pointer;
}
.search-box {
  max-width: 400px;
  width: 100%;
}
</style>
