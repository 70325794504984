<template>
  <v-container class="pt-0 pb-5 px-5" fluid>
    <div class="d_flex align-center justify-space-between">
      <div class="text-h6 font-weight-bold">
        Tình trạng bảo hành/sửa chữa ({{ "01" }})
      </div>
      <v-btn
        class="rounded-lg"
        color="primary"
        dense
        depressed
        dark
        @click="gotoCreatePage('guarantee')"
      >
        Bảo hành
      </v-btn>
    </div>
    <v-data-table
      class="datatable py-2"
      calculate-widths
      disable-pagination
      no-data-text="Không có dữ liệu"
      no-results-text="Không tìm thấy kết quả phù hợp"
      :headers="headers"
      hide-default-footer
      :loading="false"
      loading-text="Đang tải dữ liệu"
      :items="serialInfo.bh_scs"
      item-key="id"
      @click:row="viewDetail($event)"
    >
      <template v-slot:[`item.step`]="{ item }">
        <v-chip
          class="font-weight-bold"
          :color="stepList[item.step - 1].color"
          small
          outlined
        >
          {{ stepList[item.step - 1].name }}
        </v-chip>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <div class="btn--detail" @click.stop="viewDetail(item)">
          Xem chi tiết
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "Mã phiếu",
          align: "start",
          sortable: false,
          value: "code"
        },
        {
          text: "Ngày tạo",
          align: "start",
          sortable: false,
          value: "created_at"
        },
        {
          text: "Phụ trách bởi",
          align: "center",
          sortable: false,
          value: "assigned_to_user_name"
        },
        {
          text: "Trạng thái",
          align: "center",
          sortable: false,
          value: "step"
        },
        {
          text: "",
          align: "end",
          sortable: false,
          value: "action"
        }
      ],
      stepList: [
        { id: 1, name: "Đã tiếp nhận", color: "amber" },
        { id: 2, name: "Đang xử lý", color: "deep-purple" },
        { id: 3, name: "Xử lý xong", color: "blue" },
        { id: 4, name: "Đã trả khách", color: "amber" }
      ]
    };
  },
  computed: {
    guaranteeVoucherStatusRequest() {
      return this.$store.getters["GUARANTEE_VOUCHER/statusRequest"];
    },
    serialInfo() {
      return this.$store.getters["GUARANTEE_VOUCHER/serialInfo"];
    }
  },
  methods: {
    async gotoCreatePage(type) {
      await this.$store.dispatch("GUARANTEE_VOUCHER/resetGuaranteeVoucher");
      await this.$store.dispatch(
        "GUARANTEE_VOUCHER/setSerialInfo",
        this.serialInfo
      );
      await this.$router.push({
        name: "guarantee_vouchers-create",
        query: {
          type: type
        }
      });
    },
    async viewDetail(item) {
      await this.$store.dispatch(
        "GUARANTEE_VOUCHER/getGuaranteeVoucherById",
        item.id
      );

      // Getter guaranteeVoucher
      if (
        this.guaranteeVoucherStatusRequest.value ===
        "success-getGuaranteeVoucherById"
      ) {
        const guaranteeVoucher = await this.$store.getters[
          "GUARANTEE_VOUCHER/guaranteeVoucher"
        ];

        await this.$router.push({
          name: "guarantee_vouchers-detail",
          params: {
            guaranteeId: item.id
          },
          query: {
            type: guaranteeVoucher.type === 1 ? "guarantee" : "repair"
          }
        });

        await this.$store.dispatch(
          "GUARANTEE_VOUCHER_COMMENT/getGuaranteeVoucherComments",
          item.id
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.card-info {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.075) !important;
  height: 100%;
}
.btn--detail {
  cursor: pointer;
  font-size: 0.925rem;
  &:hover {
    text-decoration: underline;
  }
}
</style>
